import './Footer.style.scss';
import React from 'react';

const Footer = () => {
  const year: number = new Date().getFullYear();

  return (
    <div className='footer'>
      <div className='footer--copy-right'>
        {`© All rights reserved. morthru ${year}`}
      </div>
    </div>
  );
};

export default Footer;
