import React from 'react';
import './styles/homePage.scss';
import Logo from './assets/logo.svg';
import Footer from '../footer/Footer';

function HomePage() {
  return (
    <div className="home-page" >
      <img src={Logo} alt="morthru" className="home-page--logo" />
      <Footer />
    </div>
  );
}

export default HomePage;